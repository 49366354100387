<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="ok"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="总计">
              <a-input-number
                :precision="2"
                v-decorator="['fee', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="税额">
              <a-input-number
                :precision="2"
                v-decorator="['tax_amount', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区自营">
              <a-input-number
                :precision="2"
                v-decorator="['north_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区管委会">
              <a-input-number
                :precision="2"
                v-decorator="['north_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区济高">
              <a-input-number
                :precision="2"
                v-decorator="['north_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="税率">
              <a-input-number
                :precision="0"
                v-decorator="['tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
              <span style="margin-left: 3px">%</span>
            </a-form-item>
            <a-form-item label="日期">
              <a-month-picker v-decorator="['year_month', {rules: [{required: true, message: '必填项，请填写信息'}]}]" valueFormat="YYYY-MM" />
            </a-form-item>
            <a-form-item label="南区自营">
              <a-input-number
                :precision="2"
                v-decorator="['south_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区管委会">
              <a-input-number
                :precision="2"
                v-decorator="['south_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区济高">
              <a-input-number
                :precision="2"
                v-decorator="['south_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this)
    }
  },
  methods: {
    taxRateChange (values) {
      const fee = this.form.getFieldValue('fee')
      if (fee && values) {
        const result = ((fee * values) / 100).toFixed(2)
        this.form.setFieldsValue({ 'tax_amount': Number(result) })
      }
    },
    taxFeeChange (values) {
      const tax_rate = this.form.getFieldValue('tax_rate')
      if (tax_rate && values) {
        const result = ((tax_rate * values) / 100).toFixed(2)
        this.form.setFieldsValue({ 'tax_amount': Number(result) })
      }
    },
    ok () {
      // { $emit('ok') }
      const that = this
      this.form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values['tax_amount'], (values['fee'] - (values['fee'] / (1 + values['tax_rate'] * 0.01))).toFixed(2))
          // that.$emit('ok')
          if (Number(values['tax_amount']).toFixed(2) !== Number((values['fee'] - (values['fee'] / (1 + values['tax_rate'] * 0.01)))).toFixed(2)) {
            that.$message.warning('税额不正确')
          } else {
            const addAmount = values['south_gov'] * 100 + values['south_self'] * 100 + values['south_jg'] * 100 + values['north_gov'] * 100 + values['north_self'] * 100 + values['north_jg'] * 100
            if (Number((addAmount / 100).toFixed(2)) !== Number(values['fee'])) {
              that.$message.warning('总额不正确')
              return
            }
            that.$emit('ok')
          }
          // 新增
        } else {
        }
      })
    }
  }
}
</script>
