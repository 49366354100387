<template>
  <!--  <a-modal-->
  <!--    title="新建"-->
  <!--    :width="1080"-->
  <!--    :visible="visible"-->
  <!--    :confirmLoading="loading"-->
  <!--    @ok="() => { $emit('ok') }"-->
  <!--    @cancel="() => { $emit('cancel') }"-->
  <!--  >-->
  <!--  </a-modal>-->
  <a-card>
    <div>
      <a-spin :spinning="loading">
        <a-form :form="form">
          <a-row :gutter="[16,16]">
            <a-col :span="12">
              <a-form-item label="类别" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
                <a-select
                  @change="handleCategoryChange"
                  v-decorator="[
                    'category',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ]
                    },
                  ]"
                  placeholder="选择"
                >
                  <a-select-option
                    v-for="(val, key) in this.$Dictionaries.property_project_category"
                    :value="key"
                    :key="key">
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="名称" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
                <a-input
                  v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item
                label="用章类型"
                v-if="this.category === 1"
                :labelCol="this.formLayout.labelCol"
                :wrapperCol="this.formLayout.wrapperCol">
                <a-select
                  mode="multiple"
                  v-decorator="[
                    'seal_type',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ]
                    },
                  ]"
                  placeholder="选择"
                >
                  <a-select-option
                    v-for="(val) in this.$Dictionaries.property_project_seal_type"
                    :value="val"
                    :key="val">
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="收款类型" v-if="this.category >1 && this.category !==4" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
                <a-select
                  v-decorator="[
                    'category_fee',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ]
                    },
                  ]"
                  placeholder="选择"
                >
                  <a-select-option
                    v-for="(val, key) in this.$Dictionaries.property_project_fee_category"
                    :value="key"
                    :key="key">
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="收款方" v-if="this.category >1 && this.category !==4" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
                <a-input
                  v-decorator="['fee_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                label="风控审核"
                v-if="this.category ===1"
                :labelCol="this.formLayout.labelCol"
                :wrapperCol="this.formLayout.wrapperCol">
                <a-switch v-decorator="['is_risk', { valuePropName: 'checked' }]" />
              </a-form-item>
              <a-form-item
                label="法务审核"
                v-if="this.category === 1"
                :labelCol="this.formLayout.labelCol"
                :wrapperCol="this.formLayout.wrapperCol">
                <a-switch v-decorator="['is_law', { valuePropName: 'checked' }]" />
              </a-form-item>
              <a-form-item label="总计" v-if="this.category >1" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
                <a-input-number
                  :precision="2"
                  v-decorator="['fee', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="请输入"
                  style="width: 180px;"
                />
              </a-form-item>
              <a-form-item label="付款方式" v-if="this.category >1 && this.category !==4" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
                <a-select
                  v-decorator="[
                    'method',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ]
                    },
                  ]"
                  placeholder="选择"
                >
                  <a-select-option
                    v-for="(val, key) in this.$Dictionaries.property_project_fee_method"
                    :value="key"
                    :key="key">
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="科目" v-if="this.category >1" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
                <a-select
                  v-decorator="[
                    'subject',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ]
                    },
                  ]"
                  placeholder="选择"
                  :disabled="this.subject > 0"
                >
                  <a-select-option
                    v-for="(val, key) in this.$Dictionaries.property_project_fee_subject"
                    :value="key"
                    :key="key">
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="说明" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
                <a-textarea
                  v-decorator="['content', {rules: [{required: true, message: '必填项，请填写信息'}, {max:128, message: '输入超出长度限制'}]}]"
                  :placeholder="content_placeholder"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24" v-if="this.category ===1">
              <a-form-item label="附件列表">
                <a-button @click="addItemAttachment">添加</a-button>
                <a-table
                  bordered
                  :data-source="attachment"
                  ref="attachment_table"
                  :columns="attachment_columns"
                  :pagination="false">
                  <template slot="name" slot-scope="text, record">
                    <editable-cell :text="text" @change="onCellChange(record.key, 'name', $event)" />
                  </template>
                  <template slot="count" slot-scope="text, record">
                    <editable-cell :text="text" @change="onCellChange(record.key, 'count', $event)" />
                  </template>
                  <template slot="f" slot-scope="f, record">
                    <a :href="record.f" target="_blank" >{{ record.f }}</a>
                  </template>
                  <template slot="action" slot-scope="text, record">
                    <a-popconfirm
                      v-if="attachment.length"
                      title="确定删除吗?"
                      @confirm="() => onDeleteItem(record.key)"
                    >
                      <a href="javascript:;">删除</a>
                    </a-popconfirm>
                  </template>
                </a-table>
              </a-form-item>
            </a-col>
            <a-col :span="24" v-if="this.category >1">
              <a-form-item label="费用列表">
                <a-button @click="addItemFee">添加</a-button>
                <a-table
                  bordered
                  :data-source="fee_list"
                  ref="fee_list_table"
                  :columns="fee_list_columns"
                  :pagination="false">
                  <template slot="action" slot-scope="text, record">
                    <a-popconfirm
                      v-if="fee_list.length"
                      title="确定删除吗?"
                      @confirm="() => onDeleteFeeItem(record.key)"
                    >
                      <a href="javascript:;">删除</a>
                    </a-popconfirm>
                  </template>
                </a-table>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[16,16]">
            <a-col :span="14" style="text-align: center">
              <a-button type="primary" @click="commitData" :loading="this.confirmLoading"> 提交</a-button>
            </a-col>
            <a-col :span="10" style="text-align: left">
              <a-button @click="goBack"> 返回</a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
      <add-item-form
        v-if="item_visible"
        ref="addItemForm"
        :visible="item_visible"
        @cancel="handleAddCancel"
        @ok="handleOk"
      />
      <add-item-fee-form
        v-if="item_fee_visible"
        ref="addItemFeeForm"
        :visible="item_fee_visible"
        @cancel="handleAddFeeCancel"
        @ok="handleOkFee"
      />
    </div>
  </a-card>
</template>
<script>
import AddItemForm from '../modules/AddItemForm'
import AddItemFeeForm from '../modules/AddItemFeeForm'
import { property_project_item_create } from '@/api/property_project_item'
const EditableCell = {
  template: `
    <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-input :value="value" @change="handleChange" @pressEnter="check" />
      <a-icon
        type="check"
        class="editable-cell-icon-check"
        @click="check"
      />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value || ' ' }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
    </div>
  `,
  props: {
    text: String
  },
  data () {
    return {
      value: this.text,
      editable: false
    }
  },
  methods: {
    handleChange (e) {
      const value = e.target.value
      this.value = value
    },
    check () {
      this.editable = false
      this.$emit('change', this.value)
    },
    edit () {
      this.editable = true
    }
  }
}
export default {
  components: {
    EditableCell,
    AddItemForm,
    AddItemFeeForm
  },
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      item_visible: false,
      item_fee_visible: false,
      content_placeholder: '请输入说明',
      category_fee_disabled: false,
      confirmLoading: false,
      category: -1,
      subject: '6',
      count: 1,
      attachment: [],
      fee_list: [],
      attachment_columns: [
        {
          title: '附件名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '附件数量',
          dataIndex: 'count',
          ellipsis: true
        },
        {
          title: '用印数量',
          dataIndex: 'seal_count',
          ellipsis: true
        },
        {
          title: '文件',
          dataIndex: 'f',
          align: 'center',
          scopedSlots: { customRender: 'f' }
        },
        {
          width: 130,
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      fee_list_columns: [
        {
          title: '小计总额',
          dataIndex: 'fee',
          ellipsis: true
        },
        {
          title: '税率',
          dataIndex: 'tax_rate',
          customRender: (text) => text + '%',
          align: 'center'
        },
        {
          title: '税额',
          dataIndex: 'tax_amount',
          align: 'center'
        },
        {
          title: '北区自营',
          dataIndex: 'north_self',
          align: 'center'
        },
        {
          title: '北区管委会',
          dataIndex: 'north_gov',
          align: 'center'
        },
        {
          title: '北区济高',
          dataIndex: 'north_jg',
          align: 'center'
        },
        {
          title: '南区自营',
          dataIndex: 'south_self',
          align: 'center'
        },
        {
          title: '南区管委会',
          dataIndex: 'south_gov',
          align: 'center'
        },
        {
          title: '南区济高',
          dataIndex: 'south_jg',
          align: 'center'
        },
        {
          title: '日期',
          dataIndex: 'year_month_show',
          align: 'center',
          ellipsis: true
        },
        {
          width: 130,
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  mounted () {
    this.subject = String(this.$route.query.subject)
  },
  methods: {
    handleCategoryChange (value) {
      this.category = Number(value)
      console.log(value)
      if (this.category === 2) {
        this.content_placeholder = '付款说明'
      } else {
        this.content_placeholder = '请输入说明'
      }
      if (this.category === 3) {
        this.$nextTick(() => {
          this.category_fee_disabled = true
          this.form.setFieldsValue({ 'category_fee': '1' })
        })
      } else {
        this.$nextTick(() => {
          this.category_fee_disabled = false
          this.form.setFieldsValue({ 'category_fee': undefined })
        })
      }

      this.$nextTick(() => {
        this.form.setFieldsValue({ 'subject': this.subject })
      })
    },
    onCellChange (key, dataIndex, value) {
      const dataSource = [...this.attachment]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.attachment = dataSource
      }
    },
    onDeleteItem (key) {
      const dataSource = [...this.attachment]
      this.attachment = dataSource.filter(item => item.key !== key)
    },
    onDeleteFeeItem (key) {
      const dataSource = [...this.fee_list]
      this.fee_list = dataSource.filter(item => item.key !== key)
    },
    addItemAttachment () {
      this.item_visible = true
    },
    addItemFee () {
      this.item_fee_visible = true
    },
    handleAddCancel () {
      this.item_visible = false
    },
    handleAddFeeCancel () {
      this.item_visible = false
      this.item_fee_visible = false
    },
    handleOk () {
      const form = this.$refs.addItemForm.form
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          values.f = values.imgs
          values.count = String(values.count)
          values.seal_count = String(values.seal_count)
          values.key = '' + Date.now()
          this.attachment.push(values)
          this.item_visible = false
          // 重置表单数据
          form.resetFields()
          // 刷新表格
          this.$refs.attachment_table.refresh(true)
        } else {
        }
      })
    },
    handleOkFee () {
      const form = this.$refs.addItemFeeForm.form
      const that = this
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          values.year_month_show = values.year_month
          values.key = '' + Date.now()
          that.fee_list.push(values)
          that.item_fee_visible = false
          // 重置表单数据
          form.resetFields()
          // 刷新表格
          // that.$refs.fee_list_table.refresh(true)
        } else {
        }
      })
    },
    commitData () {
      this.confirmLoading = true
      const that = this
      console.log(that.$route.params.id, that.$route.query.item_id)
      this.form.validateFields((errors, values) => {
        if (!errors) {
          let data = {}
          if (values.category === '1') {
            data = {
              project_id: Number(that.$route.params.id),
              item_id: Number(that.$route.query.item_id),
              name: values.name,
              category: Number(values.category),
              content: values.content,
              seal: {
                is_risk: values.is_risk,
                is_law: values.is_law,
                seal_type: values.seal_type.toString(),
                attachment: this.attachment.map(item => {
                  item.key = undefined
                  item.img = undefined
                  item.imgs = undefined
                  item.count = Number(item.count)
                  item.seal_count = Number(item.seal_count)
                  return item
                })
              }
            }
          } else {
            // const dataSource = [...this.fee_list]
            // const feeList = dataSource.map(item_ => {
            //   const item = { ...item_ }
            //   item.key = undefined
            //   item.img = undefined
            //   item.imgs = undefined
            //   item.fee = (Number(item.fee) * 100).toFixed(2)
            //   item.tax_rate = Number(item.tax_rate)
            //   item.year_month = item.year_month + '-01'
            //   return item
            // })
            //
            // console.log(this.fee_list)
            // console.log(feeList)
            data = {
              project_id: Number(that.$route.params.id),
              item_id: Number(that.$route.query.item_id),
              name: values.name,
              category: Number(values.category),
              content: values.content,
              subject: Number(values.subject),
              fee: Number(Math.round(values.fee * 100).toFixed(2)),
              method: Number(values.method),
              payment_category: Number(values.category_fee),
              payment_name: values.fee_name,
              fee_list: this.fee_list.map(item_ => {
                const item = { ...item_ }
                item.key = undefined
                item.img = undefined
                item.imgs = undefined
                item.fee = Number(Math.round((item.fee) * 100).toFixed(2))
                item.north_gov = Number(Math.round((item.north_gov) * 100).toFixed(2))
                item.north_jg = Number(Math.round((item.north_jg) * 100).toFixed(2))
                item.north_self = Number(Math.round((item.north_self) * 100).toFixed(2))
                item.south_gov = Number(Math.round((item.south_gov) * 100).toFixed(2))
                item.south_jg = Number(Math.round((item.south_jg) * 100).toFixed(2))
                item.south_self = Number(Math.round((item.south_self) * 100).toFixed(2))
                item.tax_amount = Number(Math.round((item.tax_amount) * 100).toFixed(2))
                item.tax_rate = Number(item.tax_rate)
                item.year_month = item.year_month + '-01'
                return item
              })
            }
            let fee_amount = 0
            data.fee_list.forEach(item => {
              fee_amount += item.fee
            })

            console.log(data.fee, '===', fee_amount)
            if (data.fee !== fee_amount) {
              this.$message.warning('总计与小计总额之和不一致，请核算后重新提交')
              this.confirmLoading = false
              return
            }
          }
          console.log(data)

          property_project_item_create(data).then(res => {
            // this.visible = false
            // 重置表单数据
            that.$router.go(-1)
            console.log('创建成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>

.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
