<template>
  <a-modal
    title="新建"
    :width="780"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handelsubmit"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="上传" v-show="false">
              <a-textarea v-decorator="['imgs', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="文件名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="文件上传">
              <div class="clearfix">
                <a-upload
                  v-decorator="[
                    'img',
                    {
                      valuePropName: 'img'
                    },
                  ]"
                  name="file"
                  :action="actionUrl"
                  :data="new_multipart_params"
                  list-type="picture-card"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  @preview="handlePreview"
                  @change="handleChange"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="文件数量">
              <a-input-number v-decorator="['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="用印数量">
              <a-input-number v-decorator="['seal_count', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import { oss_token_list } from '@/api/oss_token'
import { zone_property_cell_img_delete } from '@/api/zone_property_cell'
// 表单字段
const fields = ['imgs']

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      fileList: [],
      filePathList: [],
      filePathUrls: ''
    }
  },
  created () {
    // if (this.model.imgs !== undefined && this.model.imgs.length > 0) {
    //   this.fileList = this.model.imgs.map((item, index) => {
    //     item.url = item.img
    //     item.uid = index
    //     item.status = 'done'
    //     item.name = item.img.substring(item.img.lastIndexOf('/') + 1)
    //     this.filePathUrls = this.filePathUrls + ',' + item.img
    //     return item
    //   })
    // }
    oss_token_list().then(({ data }) => {
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
  },
  methods: {
    handelsubmit () {
      this.$emit('ok')
    },
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      const lastName = file.lastModified + '.' + suffix
      this.new_multipart_params.key = file.uid + '_' + lastName
      const isLt50M = file.size / 1024 / 1024 < 50
      return isLt50M
    },
    // 进口图片
    async handlePreview (file) {
      console.log('------------------------------------>>>>>>>>>>', file)
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      console.log('我要预览', file.preview)
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      if (file.status === 'done') {
        this.filePathList.push(this.actionUrl + '/' + this.new_multipart_params.key)
        this.form.setFieldsValue({ imgs: this.filePathList.toString() })
      } else if (file.status === 'removed') {
        console.log(this.filePathUrls)
        console.log(file.url)
        if (this.filePathUrls.search(file.url) >= 0) {
          zone_property_cell_img_delete(file.id).then(res => {
            console.log(res)
            this.model.imgs.slice(this.filePathUrls.search(file.url), 1)
          })
          return
        }
        this.filePathList = this.filePathList.filter(function (item) {
          return !(item.search(file.name) > 0)
        })
        this.form.setFieldsValue({ imgs: this.filePathList.toString() })
      }
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    }
  }
}
</script>
